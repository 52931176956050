import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { ServerService } from 'src/app/server.service';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// Interface for dialog data passed when opening the dialog
export interface DialogData {
  hash: string;  // The hash associated with the report
}

@Component({
  selector: 'app-report-auth',
  templateUrl: './report-auth.component.html',
  styleUrls: ['./report-auth.component.css']
})
export class ReportAuthComponent {
  
  // Emit the verification data to parent component after successful verification
  @Output() verificationData = new EventEmitter<any>();

  // Form fields for email and token
  email: string = '';
  token: string = '';
  
  // Flags to manage validation and form states
  emailVerified: boolean = false;  // Flag to show token input form after email is verified
  tokenError: boolean = false;     // Flag to show token error message
  emailError: boolean = false;     // Flag to show email error message
  isEmailValid: boolean = false;   // Flag for email validation
  isTokenValid: boolean = false;   // Flag for token validation
  isLoading: boolean = false;      // Flag for loading state when making API requests

  constructor(
    private serverService: ServerService,               // Inject the service to handle API requests
    public dialogRef: MatDialogRef<ReportAuthComponent>, // Inject MatDialogRef to close dialog
    @Inject(MAT_DIALOG_DATA) public data: DialogData    // Inject dialog data (hash) when opened
  ) {}

  /**
   * Validate the email input using a regular expression.
   */
  validateEmail(): void {
    const emailRegex = new RegExp('^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})$');
    this.isEmailValid = emailRegex.test(this.email);
  }

  /**
   * Validate the token input, expecting an 8-digit numeric code.
   */
  validateToken(): void {
    const tokenRegex = /^[0-9]{8}$/;  // Token must be an 8-digit number
    this.isTokenValid = tokenRegex.test(this.token);
  }

  /**
   * Send a verification email to the user with the encrypted hash.
   */
  sendEmail() {
    this.isLoading = true;  // Show loading indicator
    const jsonStr = JSON.stringify({ email: this.email, hash: this.data.hash });  // Prepare data to send
    const encryptedObject = CryptoJS.AES.encrypt(jsonStr, environment.secretKey).toString();  // Encrypt the data

    // Call the server service to send the report token email
    this.serverService.SendReportTokenMail({ data: encryptedObject }).then(
      response => {
        if (response.out !== "error") {
          this.emailVerified = true;  // Email is successfully verified
          this.isLoading = false;
        } else {
          this.emailError = true;  // Show error if response indicates failure
          this.isLoading = false;
        }
      },
      error => {
        this.emailError = true;  // Handle API error
        this.isLoading = false;
      }
    );
  }

  /**
   * Verify the token entered by the user with the server.
   */
  verifyToken() {
    this.isLoading = true;  // Show loading indicator
    const jsonStr = JSON.stringify({ email: this.email, token: this.token, hash: this.data.hash });  // Prepare data to send
    const encryptedObject = CryptoJS.AES.encrypt(jsonStr, environment.secretKey).toString();  // Encrypt the data

    // Call the server service to verify the report token
    this.serverService.VerifyReportToken({ data: encryptedObject }).then(
      (response: any) => {
        if (response && typeof response === 'object' && 'isValid' in response) {
          if (response.isValid) {
            this.tokenError = false;  // Token is valid
            this.verificationData.emit(response.data);  // Emit the verification data to parent component
          } else {
            this.tokenError = true;  // Token is invalid, show error message
            this.isLoading = false;
          }
        } else {
          // If response does not have the expected format
          console.error('Unexpected response format');
          this.tokenError = true;
          this.isLoading = false;
        }
      },
      error => {
        // Handle API error during token verification
        console.error('Verification error');
        this.tokenError = true;
        this.isLoading = false;
      }
    );
  }
}
