// github.service.ts
import { Injectable } from '@angular/core';
import { Octokit } from '@octokit/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerService } from './server.service';
import { environment } from './environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class GithubService {
  // Define private variables for environment configurations
  private clientId = environment.clientId;
  private clientIdApp = environment.clientIdApp;
  private homeUrl = environment.homeUrl;
  private githubUrl = environment.githubUrl;
  private secretKeyCookie = environment.secretKeyCookie;
  private octokit: Octokit;

  // BehaviorSubject to track user authentication state
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

  constructor(public server: ServerService) {
    // Initialize Octokit without authentication initially
    this.octokit = new Octokit();

    // Check if there's an encrypted access token in sessionStorage
    const encryptedAccessToken = sessionStorage.getItem('token');
    if (encryptedAccessToken) {
      const accessToken = CryptoJS.AES.decrypt(encryptedAccessToken, this.secretKeyCookie).toString(CryptoJS.enc.Utf8);
      if (accessToken) {
        // Reinitialize Octokit with the decrypted access token
        this.octokit = new Octokit({ auth: accessToken });
        this.isAuthenticatedSubject.next(true);
      }
    }
  }

  /**
   * Redirects user to GitHub login page for OAuth authentication.
   */
  login(): void {
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${this.clientIdApp}`;
  }

  /**
   * Handles the OAuth callback and exchanges the authorization code for an access token.
   */
  async handleCallback(): Promise<void> {
    const code = new URLSearchParams(window.location.search).get('code');
    if (code != null) {
      await this.server.getCallback({ code }).then((res: any) => {
        if (res.out !== 'error') {
          // Store the encrypted token in sessionStorage
          sessionStorage.setItem('token', res.out);
          const accessToken = CryptoJS.AES.decrypt(res.out, this.secretKeyCookie).toString(CryptoJS.enc.Utf8);
          if (accessToken) {
            // Set the token in Octokit and update the auth state
            this.octokit = new Octokit({ auth: accessToken });
            this.isAuthenticatedSubject.next(true);
          }
        }
      });
    }
    // Redirect user back to home page after handling the callback
    window.location.href = this.homeUrl;
  }

  /**
   * Fetches repositories for the authenticated user.
   * @returns Observable of the user's repositories.
   */
  getRepos(): Observable<any[]> {
    try {
      const repos = this.octokit.request('GET /user/repos');
      return from(repos).pipe(map((response) => response.data));
    } catch (error) {
      console.error('Error fetching repositories:', error);
      return new Observable<any[]>(); // Return an empty observable in case of error
    }
  }

  /**
   * Fetches details of the authenticated user.
   * @returns Observable of user details.
   */
  getUserDetails(): Observable<any> {
    try {
      return from(this.octokit.request('GET /user'));
    } catch (error) {
      console.error('Error fetching user details:', error);
      return new Observable<any>(); // Return an empty observable in case of error
    }
  }

  /**
   * Fetches the user's installations (for GitHub Apps).
   * @returns Observable of user installations.
   */
  getUserInstall(): Observable<any> {
    try {
      return from(this.octokit.request('GET /user/installations'));
    } catch (error) {
      console.error('Error fetching user installations:', error);
      return new Observable<any>(); // Return an empty observable in case of error
    }
  }

  /**
   * Fetches branches of a specific repository.
   * @param repo The repository name.
   * @param name The repository owner.
   * @returns Observable of repository branches.
   */
  getBranches(repo: string, name: string): Observable<any[]> {
    try {
      const branches = this.octokit.request('GET /repos/{owner}/{repo}/branches', {
        owner: name,
        repo: repo,
      });
      return from(branches).pipe(map((response) => response.data));
    } catch (error) {
      console.error('Error fetching branches:', error);
      return new Observable<any[]>(); // Return an empty observable in case of error
    }
  }

  /**
   * Fetches commits of a specific branch in a repository.
   * @param repo The repository name.
   * @param branch The branch name.
   * @param name The repository owner.
   * @returns Observable of commits.
   */
  getCommits(repo: string, branch: string, name: string): Observable<any[]> {
    try {
      const commits = this.octokit.request('GET /repos/{owner}/{repo}/commits', {
        owner: name,
        repo: repo,
        sha: branch,
      });
      return from(commits).pipe(map((response) => response.data));
    } catch (error) {
      console.error('Error fetching commits:', error);
      return new Observable<any[]>(); // Return an empty observable in case of error
    }
  }

  /**
   * Logs out the user by clearing session data and resetting the authentication state.
   */
  logout(): void {
    const code = sessionStorage.getItem('token');
    if (code) {
      this.server.deleteToken({ key: code });
    }
    sessionStorage.clear();
    this.isAuthenticatedSubject.next(false);
    this.server.logoutGithub.emit(); // Emit event to notify about logout
  }
}
