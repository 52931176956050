import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ServerService } from '../../server.service'
import { environment } from 'src/app/environments/environment'
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css'],
})
export class ResultComponent implements OnInit {
  public displayData: any;         // Stores the data to be displayed on the result page
  public notionDisplayURL: any;    // URL for the Notion display
  public shareOnTelegram: any;     // URL for sharing the result on Telegram
  public shareOnTwitter: any;      // URL for sharing the result on Twitter
  public timeZone: any;            // Stores the time zone data
  public URLHash: any;             // Hash retrieved from the URL
  public URL: any;                 // Base URL of the application
  public LAB_URL: any;             // Lab environment URL
  public linkButton: any;          // URL for an external button link
  public isMobile: boolean = false;  // Flag to detect if the user is on mobile
  public isUpload: boolean = false;  // Flag to check if the result is an upload
  public isLoading: boolean = true;  // Flag to manage the loading state of the page

  constructor(
    private route: ActivatedRoute,   // Manages the route parameters
    private serverService: ServerService,   // Service to communicate with the backend
    public server: ServerService,    // Public instance of the server service for template access
    private titleService: Title,     // Manages the title of the page
    private meta: Meta,              // Manages meta tags for SEO and social sharing
    private router: Router           // Router service for navigation
  ) {
    // Initialize displayData with default values
    this.displayData = {
      stoneScore: '',
      message: '',
      creationTime: '',
      expirationTime: '',
      repositoryType: '',
      GitHubURL: '',
      notionURL: '',
      pageURL: '',
      auditBookingURL: '',
      fileName: ''
    }

    // Detect the screen width and set the isMobile flag
    const screenWidth = window.innerWidth;
    const mobileScreenWidthThreshold = 768; // Threshold for mobile devices
    this.isMobile = screenWidth < mobileScreenWidthThreshold;

    // Pass the mobile flag to the server service
    this.server.isMobile = this.isMobile;
  }

  async ngOnInit() {
    // Set the page title and meta tags for SEO and social media sharing
    this.titleService.setTitle(`Result`);
    this.meta.updateTag({ name: 'description', content: 'Detailed results of your pre-audit' });
    this.meta.updateTag({ property: 'og:title', content: 'Audit - Result Page' });
    this.meta.updateTag({ property: 'og:description', content: 'Detailed results of your -preaudit' });
    this.meta.updateTag({ property: 'og:image', content: 'https://mantoine.audit.setinstone.fr/assets/logosetinstoneTW.png' });
    this.meta.updateTag({ property: 'og:url', content: 'https://mantoine.audit.setinstone.fr/result' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:title', content: 'Audit - Result Page' });
    this.meta.updateTag({ name: 'twitter:description', content: 'Detailed results of your pre-audit' });
    this.meta.updateTag({ name: 'twitter:image', content: 'https://mantoine.audit.setinstone.fr/assets/logosetinstoneTW.png' });

    // Retrieve the parameter from the URL
    this.route.params.subscribe(async (params) => {
      const paramValue = params['param']
      this.URLHash = paramValue

      const URLToEncode = environment.homeUrl + '/results/' + this.URLHash
      const message = encodeURIComponent(environment.telegram_message);
      this.shareOnTelegram = `https://t.me/share/url?url=${encodeURIComponent(URLToEncode)}&text=${message}`

      // Set the base URLs from the environment
      this.URL = environment.homeUrl
      this.LAB_URL = environment.labUrl
      this.linkButton = environment.linkButton

      // Fetch result data using the hash
      await this.serverService.handleHash({ hash: paramValue }).then(async (res: any) => {
        if (res.out == "error") {
          this.router.navigate(['/'])
        }
        this.displayData = res
        if (this.displayData.GitHubURL == "upload") {
          this.isUpload = true;
        }
        this.setTwitterLink(this.displayData.GitHubURL)
        this.isLoading = false
      })
    })

    this.displayData.repositoryType = this.displayData.repositoryType.toUpperCase()
  }

  async setTwitterLink(data: any) {
    const link = environment.homeUrl + '/results/' + this.URLHash
    const message = encodeURIComponent(environment.xmessage)
    this.shareOnTwitter = `https://twitter.com/intent/tweet?text=${message}&url=${encodeURIComponent(link)}`;
  }

  async getDisplayData(this: any) {
    const displayData = await this.http.get(environment.requestUrl + '/resultData/' + this.URLHash).toPromise()

    return displayData ? displayData : 'Could not fetch the data from the API';
  }
  // Function to handle creation time and adjust based on timezone offset
  async getCreationTimeOffset() {
    let creationTimeOffset = new Date(await this.displayData.creationTime).getTimezoneOffset();
    let creationTimeZoneOffset = 0;

    // Adjust time offset
    while (creationTimeOffset !== 0) {
      if (creationTimeOffset < 0) {
        creationTimeOffset += 60;
        creationTimeZoneOffset++;
      } else if (creationTimeOffset > 0) {
        creationTimeOffset -= 60;
        creationTimeZoneOffset--;
      }
    }

    // Return the adjusted creation time
    return creationTimeZoneOffset === 0
      ? this.displayData.creationTime
      : this.displayData.creationTime + (creationTimeZoneOffset < 0 ? ' - ' : ' + ') + creationTimeZoneOffset;
  }

  // Function to handle expiration time and adjust based on timezone offset
  async getExpirationTimeOffset() {
    let expirationTimeOffset = new Date(await this.displayData.expirationTime).getTimezoneOffset();
    let expirationTimeZoneOffset = 0;

    // Adjust time offset
    while (expirationTimeOffset !== 0) {
      if (expirationTimeOffset < 0) {
        expirationTimeOffset += 60;
        expirationTimeZoneOffset++;
      } else if (expirationTimeOffset > 0) {
        expirationTimeOffset -= 60;
        expirationTimeZoneOffset--;
      }
    }

    // Return the adjusted expiration time
    return expirationTimeZoneOffset === 0
      ? this.displayData.expirationTime
      : this.displayData.expirationTime + (expirationTimeZoneOffset < 0 ? ' - ' : ' + ') + expirationTimeZoneOffset;
  }

  // Function to copy the result URL to clipboard
  copyToClipboard() {
    navigator.clipboard.writeText(environment.homeUrl + '/results/' + this.URLHash);
  }
}
