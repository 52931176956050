<div class="change-password-container">
  <h2>Change Your Password</h2>

  <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <input
        type="password"
        formControlName="password"
        placeholder="Password"
      />
      <div *ngIf="password?.invalid && (password?.dirty || password?.touched)">
        <small *ngIf="password?.errors?.['required']">Password is required</small>
        <small *ngIf="!password?.errors?.['required']">
          <p>Password must have at least:</p>
          <ul>
            <li>1 uppercase letter</li>
            <li>1 lowercase letter</li>
            <li>1 digit</li>
            <li>1 special character</li>
            <li>A minimum length of 8 characters</li>
          </ul>
          
        </small>
      </div>
    </div>

    <div class="form-group">
      <input
        type="password"
        formControlName="confirmPassword"
        placeholder="Confirm Password"
      />
      <div *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)">
        <small *ngIf="confirmPassword?.errors?.['required']">
          Confirmation is required
        </small>
        <small *ngIf="changePasswordForm?.errors?.['mismatch']">
          Passwords do not match
        </small>
      </div>
    </div>

    <div class="actions">
      <button type="button" (click)="onCancel()">Cancel</button>

      <button type="submit" class="change-button" [disabled]="changePasswordForm.invalid">
      Change
    </button>
    </div>
  </form>
</div>
