import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ServerService } from '../../server.service'
import Swal from 'sweetalert2'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { GithubService } from 'src/app/github.service'
import { environment } from '../../environments/environment'
import { ReCaptchaV3Service } from 'ng-recaptcha'
import * as CryptoJS from 'crypto-js'
import { Title } from '@angular/platform-browser';

// Interface for form data
interface FormData {
  firstname: string
  lastname: string
  email: string
  website: string
  telegram: string
  tools: string
  github: string
  description: string
  termsAccepted: boolean
  infoAgreement: boolean
  selectedPlan: string
  goal: string
  upload: boolean
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: false },
    },
  ],
})

/* eslint-disable @typescript-eslint/no-explicit-any */
export class FormComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef

  // Initialize form data
  formData: FormData = {
    firstname: '',
    lastname: '',
    email: '',
    website: '',
    telegram: '@',
    tools: '',
    github: '',
    description: '',
    termsAccepted: false,
    infoAgreement: false,
    selectedPlan: 'free',
    goal: '',
    upload: false,
  }

  // State variables
  isMobile: boolean = false;
  isLinear = true;
  isAuthenticated: boolean = false;
  isSendDisable: boolean = false;
  isLoading: boolean = false;
  isGithubSet: boolean = false;

  // Error flags
  showFirstFormError = false;
  showSecondFormError = false;
  showThirdFormError = false;

  // Links from environment
  notionRulesLink = environment.linkNotionRules;
  rulesLink = environment.generalRules;

  // File upload variables
  selectedFile: File | undefined;
  fileError: string | undefined;

  constructor(
    public server: ServerService,
    private githubService: GithubService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private titleService: Title,
  ) {
    this.isMobile = this.server.isMobile;
  }

  ngOnInit() {
    this.titleService.setTitle(`Sec Assistant - Set In Stone`);
    this.server.logoutGithub.subscribe(() => {
      this.isAuthenticated = false
    })

    this.githubService.isAuthenticated$.subscribe((isAuth: boolean) => {
      if (isAuth) {
        this.isAuthenticated = true
        this.server.isAuth = true
      }
    })
  }

  // Form validation methods
  checkFirstForm() {
    if (!this.formData.firstname || !this.formData.lastname || !this.formData.email) {
      this.showThirdFormError = true
    } else if (!this.formData.website) {
      this.showSecondFormError = false
    }
  }

  checkSecondForm() {
    if (!this.formData.tools || !this.formData.website || !this.formData.github) {
      this.showSecondFormError = true
    } else {
      this.showSecondFormError = false
    }
  }

  checkEmail() {
    const emailRegex = new RegExp('^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})$')
    const isEmailValid = emailRegex.test(this.formData.email)

    return isEmailValid
  }

  checkLastName() {
    const nameRegex = new RegExp('^[A-Za-z]+([ -][A-Za-z]+)*$')
    const isNameValid = nameRegex.test(this.formData.lastname)

    return isNameValid
  }

  checkTelegram() {
    const telegramRegex = new RegExp('^[a-zA-Z0-9_]{5,50}$');
    const isTelegramValid = telegramRegex.test(this.formData.telegram.replace(/^@/, ''));

    return isTelegramValid;
 }


  checkFirstName() {
    const nameRegex = new RegExp('^[A-Za-z]+([ -][A-Za-z]+)*$')
    const isNameValid = nameRegex.test(this.formData.firstname)

    return isNameValid
  }

  checkPlan() {
    if (this.formData.selectedPlan === 'free' || this.formData.selectedPlan === 'lvl1') {
      return true
    }

    return false
  }

  checkReason() {
    if (this.formData.goal != '') {
      return true
    }

    return false
  }

  async checkGithubSize() {
    interface RepoSize {
      out: number
    }

    let size: RepoSize

    try {
      let tmpUrl = this.formData.github.replace(/\/tree.*$/, '')
      const response = await this.server.getRepoSize({ url: tmpUrl })
      if (response !== null && typeof response === 'object' && 'out' in response) {
        size = response as RepoSize

        if (size.out <= environment.maxSizeMb) {
          return true
        } else {
          Swal.fire({
            icon: 'error',
            title: `<span style='color: white;'>Repository size > ${environment.maxSizeMb} Mb.</span>`,
            html: `<span style='color: white;'>Please tray again with a different repository. Current repository size: ${size.out} Mb.</span>`,
            background: '#1C1C1C',
            iconColor: '#FF0000',
            confirmButtonColor: '#424242',
            confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
          })
          return false
        }
      } else {
        throw new Error('Invalid size structure.')
      }
    } catch (error) {
      console.error('Error getting repo size:', error)
      return false
    }
  }

  checkGithubUrl() {
    if (this.selectedFile != undefined) {
      return true
    }
    if (this.server.isAuth) {
      if (this.server.isRepoDataSet) {
        if (this.server.repoData.size > environment.maxSizeMb) {
          Swal.fire({
            icon: 'error',
            title: `<span style='color: white;'>Repository size > ${environment.maxSizeMb} Mb.</span>`,
            html: `<span style='color: white;'>Please try again with a different repository. Current repository size: ${this.server.repoData.size} Ko.</span>`,
            background: '#1C1C1C',
            iconColor: '#FF0000',
            confirmButtonColor: '#424242',
            confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
          })
          return false
        }
        return true
      } else {
        Swal.fire({
          icon: 'error',
          title: "<span style='color: white;'>Repository not selected</span>",
          html: `<span style='color: white;'>Please select a repository and try again.</span>`,
          background: '#1C1C1C',
          iconColor: '#FF0000',
          confirmButtonColor: '#424242',
          confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
        })
        return false
      }
    } else {
      const githubUrlRegex = new RegExp(
        '^(https?://)?(www.)?github.com/([A-Za-z0-9_.-]+/[A-Za-z0-9_.-]+)(/tree/[A-Za-z0-9_.-]+)?(.git)?$',
      )

      const isGithubUrlValid = githubUrlRegex.test(this.formData.github)

      if (isGithubUrlValid) {
        this.isGithubSet = true;
        return true
      } else {
        return false
      }
    }
  }

  // File handling methods
  onFileDropped(event: DragEvent): void {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer?.files
    if (files && files.length > 0) {
      this.handleFileUploadTest(files[0])
    }
  }

  onFileOver(event: DragEvent): void {
    event.preventDefault()
    event.stopPropagation()
  }

  onFileSelected(event: any): void {
    const fileList: FileList = event.target.files
    if (fileList.length > 0) {
      const selectedFile = fileList[0]
      if (selectedFile !== this.selectedFile) {
        this.handleFileUploadTest(selectedFile)
      }
    }
  }

  handleFileUploadTest(file: File): void {
    // Check if the file type is .zip, .tar, or .tar.gz
    if (
        file.type !== 'application/zip' &&
        file.type !== 'application/x-zip-compressed' &&
        file.type !== 'application/x-tar' &&
        !file.name.endsWith('.tar.gz')
    ) {
        this.fileError = 'Please select a .zip, .tar, or .tar.gz file.'
        return
    }

    if (file.size > environment.sizeUpload * 1024 * 1024) {
        // Convert MB to bytes
        this.fileError = 'File size exceeds 100 MB limit.'
        return
    }

    this.selectedFile = file
    this.fileError = undefined
    this.server.isSelectedFile = true
    this.server.updateIsSelectedFile.emit()
}


  removeSelectedFile(event: Event): void {
    event.stopPropagation()
    this.selectedFile = undefined
    this.fileError = undefined
    this.server.isSelectedFile = false
    this.server.updateIsSelectedFile.emit()

    // Clear the file input value after removing the file
    const fileInput = document.getElementById('fileInput') as HTMLInputElement
    if (fileInput) {
      // Create a new input element and replace the existing one
      const newFileInput = document.createElement('input')
      newFileInput.type = 'file'
      newFileInput.id = 'fileInput'
      newFileInput.accept = '.zip'
      newFileInput.style.display = 'none'
      newFileInput.addEventListener('change', (event) => this.onFileSelected(event)) // Add event listener
      fileInput.parentNode?.replaceChild(newFileInput, fileInput)
    }
  }

  formatBytes(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i]
  }

  async handleToken(token: any) {
    try {
      const res = await this.server.getScore({ token: token })
      return res
    } catch (error) {
      return false
    }
  }

  async submitForm() {
    this.isLoading = true
    // Check if the terms and conditions and information agreement checkboxes are checked
    if (!this.formData.termsAccepted || !this.formData.infoAgreement) {
      this.isLoading = false
      Swal.fire({
        icon: 'error',
        title: "<span style='color: white;'>Terms not accepted</span>",
        html: "<span style='color: white;'>You must accept the terms and conditions and the information agreement to submit the form.</span>",
        background: '#1C1C1C',
        iconColor: '#FF0000',
        confirmButtonColor: '#424242',
        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
      })
      return
    }

    this.isSendDisable = true
    setTimeout(() => {
      this.isSendDisable = false
    }, 30000)

    const showConfirmationAlert = async (errorMessage?: string, errorTitle?: string) => {
      let isValid = false
      let isSet = false
      let error = 0
      this.isLoading = false
      let upload: boolean = false

      if (this.selectedFile != undefined) {
        upload = true
        this.formData.upload = true
      }

      const result = await Swal.fire({
        input: 'text',
        title: `<span style='color: white;'>${errorTitle || 'Please enter your confirmation code.'}</span>`,
        html: `<span style='color: white;'>${
          errorMessage || 'The confirmation code has been sent to you by email.'
        }</span>`,
        inputAttributes: {
          autocapitalize: 'off',
          style: 'color: white; font-family: Inconsolata, monospace',
        },
        showCancelButton: true,
        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>Confirm</span>",
        background: '#1C1C1C',
        iconColor: '#FF0000',
        confirmButtonColor: '#00B050',
        cancelButtonText: "<span style='font-family: Inconsolata, monospace'>Cancel</span>",
        showLoaderOnConfirm: true,
        preConfirm: (token) => {
          // Serialize the object to a JSON string before encryption
          const obj = this.server.isAuth
            ? {
                token: token,
                email: this.formData.email,
                form: this.formData,
                repo: this.server.repoData,
                upload: upload,
              }
            : { token: token, email: this.formData.email, form: this.formData, upload: upload }

          const jsonStr = JSON.stringify(obj) // Convert the object to a JSON string
          const encryptedObject = CryptoJS.AES.encrypt(jsonStr, environment.secretKey).toString()

          return this.server
            .VerifyToken({ data: encryptedObject })
            .then(async (response: any) => {
              if (response) {
                isValid = response.isValid
                isSet = response.isSet
                error = response.error

                if (isValid && this.selectedFile != undefined && !isSet) {
                  try {
                    const formDataJson = JSON.stringify(this.formData)

                    // Create a new FormData object to append the encrypted data
                    const encryptedFormData = new FormData()
                    encryptedFormData.append(
                      'formData',
                      CryptoJS.AES.encrypt(formDataJson, environment.secretKey).toString(),
                    )
                    encryptedFormData.append('file', this.selectedFile)

                    const response = await this.server.uploadFile(encryptedFormData)
                    if (response.isSet === true) {
                      Swal.fire({
                        icon: 'success',
                        title: "<span style='color: white;'>Your code is being processed !</span>",
                        html: `<span style='color: white;'>You will receive an email including the results of your analyses. Note that to correctly identify vulnerabilities, the scan may take up to several minutes. </br> </br> The email will be sent by the following address: <strong>${environment.email}.</span>`,
                        background: '#1C1C1C',
                        iconColor: '#00B050',
                        confirmButtonColor: '#424242',
                        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
                      }).then(() => {
                        this.isLoading = false
                        if (this.server.isAuth) {
                          this.githubService.logout()
                        } else {
                          window.location.href = environment.homeUrl
                        }
                      })
                    }
                  } catch (error) {
                    console.error('File upload failed:', error)
                  }
                }

                if (!isValid) {
                  this.isLoading = false
                  await showConfirmationAlert('Please try again.', 'Incorrect confirmation code.')
                } else {
                  if (isSet) {
                    Swal.fire({
                      icon: 'success',
                      title: "<span style='color: white;'>Your code is being processed !</span>",
                      html: `<span style='color: white;'>You will receive an email including the results of your analyses. Note that to correctly identify vulnerabilities, the scan may take up to several minutes. </br> </br> The email will be sent by the following address: <strong>${environment.email}.</span>`,
                      background: '#1C1C1C',
                      iconColor: '#00B050',
                      confirmButtonColor: '#424242',
                      confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
                    }).then(() => {
                      this.isLoading = false
                      if (this.server.isAuth) {
                        this.githubService.logout()
                      } else {
                        window.location.href = environment.homeUrl
                      }
                    })
                  } else if (!isSet && error == 0 && !upload) {
                    this.isLoading = false
                    Swal.fire({
                      icon: 'error',
                      title: "<span style='color: white;'>Invalid Github Repository</span>",
                      html: "<span style='color: white;'>The Github repository URL you provided is invalid.</span>",
                      background: '#1C1C1C',
                      iconColor: '#FF0000',
                      confirmButtonColor: '#424242',
                      confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
                    })
                  } else if (!isSet && error == 1) {
                    this.isLoading = false
                    Swal.fire({
                      icon: 'error',
                      title: "<span style='color: white;'>Empty Github Repository</span>",
                      html: "<span style='color: white;'>The Github repository you provided is empty.</span>",
                      background: '#1C1C1C',
                      iconColor: '#FF0000',
                      confirmButtonColor: '#424242',
                      confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
                    })
                  }
                }
              }
            })
            .catch(async (error) => {
              this.isLoading = false
              await showConfirmationAlert('Please try again.', 'Incorrect confirmation code.')
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      })

      if (!result.isConfirmed) {
        return
      }
    }

    if (
      this.checkEmail() &&
      this.checkLastName() &&
      this.checkFirstName() &&
      this.checkGithubUrl() &&
      this.checkPlan() &&
      this.checkReason() &&
      this.checkTelegram()
    ) {
      //Generate token and verify it
      this.recaptchaV3Service.execute('submit').subscribe((token) =>
        this.handleToken(token).then(async (result: any) => {
          //Si captcha valide
          if (result.out) {
            if (this.isAuthenticated) {
              const encryptedEmail = CryptoJS.AES.encrypt(this.formData.email, environment.secretKey).toString()

              await this.server
                .SendTokenMail({ data: encryptedEmail })
                .then((res: any) => {
                  if (res.out == 'success') {
                    showConfirmationAlert()
                  }
                })
                .catch((err) => {
                  if (err.status == 429) {
                    this.isLoading = false

                    Swal.fire({
                      icon: 'error',
                      title: "<span style='color: white;'>Too many request</span>",
                      html: "<span style='color: white;'>Please tray again later.</span>",
                      background: '#1C1C1C',
                      iconColor: '#FF0000',
                      confirmButtonColor: '#424242',
                      confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
                    })
                  }
                })
            } else if (this.selectedFile === undefined) {
              await this.checkGithubSize().then(async (res) => {
                if (res) {
                  const encryptedEmail = CryptoJS.AES.encrypt(this.formData.email, environment.secretKey).toString()
                  await this.server
                    .SendTokenMail({ data: encryptedEmail })
                    .then((res: any) => {
                      if (res.out == 'success') {
                        showConfirmationAlert()
                      }
                    })
                    .catch((err) => {
                      if (err.status == 429) {
                        this.isLoading = false
                        Swal.fire({
                          icon: 'error',
                          title: "<span style='color: white;'>Too many request</span>",
                          html: "<span style='color: white;'>Please tray again later.</span>",
                          background: '#1C1C1C',
                          iconColor: '#FF0000',
                          confirmButtonColor: '#424242',
                          confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
                        })
                      }
                    })
                }
              })
            } else if (this.selectedFile != undefined) {
              const encryptedEmail = CryptoJS.AES.encrypt(this.formData.email, environment.secretKey).toString()
              await this.server
                .SendTokenMail({ data: encryptedEmail })
                .then((res: any) => {
                  if (res.out == 'success') {
                    showConfirmationAlert()
                  }
                })
                .catch((err) => {
                  if (err.status == 429) {
                    this.isLoading = false
                    Swal.fire({
                      icon: 'error',
                      title: "<span style='color: white;'>Too many request</span>",
                      html: "<span style='color: white;'>Please tray again later.</span>",
                      background: '#1C1C1C',
                      iconColor: '#FF0000',
                      confirmButtonColor: '#424242',
                      confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
                    })
                  }
                })
            }
          } else {
            this.isLoading = false
            Swal.fire({
              icon: 'error',
              title: "<span style='color: white;'>Too many request</span>",
              html: "<span style='color: white;'>Please tray again later.</span>",
              background: '#1C1C1C',
              iconColor: '#FF0000',
              confirmButtonColor: '#424242',
              confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
            })
          }
        }),
      )
    } else if (!this.checkEmail()) {
      this.isLoading = false
      Swal.fire({
        icon: 'error',
        title: "<span style='color: white;'>Invalid email</span>",
        html: "<span style='color: white;'>The email you provided is invalid.</span>",
        background: '#1C1C1C',
        iconColor: '#FF0000',
        confirmButtonColor: '#424242',
        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
      })
    } else if (!this.checkPlan()) {
      this.isLoading = false
      Swal.fire({
        icon: 'error',
        title: "<span style='color: white;'>Invalid Level of Analysis</span>",
        html: "<span style='color: white;'>The level of analysis you provided is invalid.</span>",
        background: '#1C1C1C',
        iconColor: '#FF0000',
        confirmButtonColor: '#424242',
        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
      })
    } else if (!this.checkReason()) {
      this.isLoading = false
      Swal.fire({
        icon: 'error',
        title: "<span style='color: white;'>Missing fields</span>",
        html: "<span style='color: white;'>You have not completed the information related to your profile</span>",
        background: '#1C1C1C',
        iconColor: '#FF0000',
        confirmButtonColor: '#424242',
        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
      })
    } else if (!this.checkLastName()) {
      this.isLoading = false
      Swal.fire({
        icon: 'error',
        title: "<span style='color: white;'>Invalid lastname</span>",
        html: "<span style='color: white;'>The lastname you provided is invalid.</span>",
        background: '#1C1C1C',
        iconColor: '#FF0000',
        confirmButtonColor: '#424242',
        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
      })
    } else if (!this.checkFirstName()) {
      this.isLoading = false
      Swal.fire({
        icon: 'error',
        title: "<span style='color: white;'>Invalid firstname</span>",
        html: "<span style='color: white;'>The firstname you provided is invalid.</span>",
        background: '#1C1C1C',
        iconColor: '#FF0000',
        confirmButtonColor: '#424242',
        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
      })
    } else if (!this.checkTelegram()) {
      this.isLoading = false
      Swal.fire({
        icon: 'error',
        title: "<span style='color: white;'>Invalid Telegram username</span>",
        html: "<span style='color: white;'>The Telegram username you provided is invalid.</span>",
        background: '#1C1C1C',
        iconColor: '#FF0000',
        confirmButtonColor: '#424242',
        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
      })
    } else if (!this.checkGithubUrl()) {
      this.isLoading = false
      Swal.fire({
        icon: 'error',
        title: "<span style='color: white;'>Invalid Github URL</span>",
        html: "<span style='color: white;'>The Github repository URL you provided is invalid.</span>",
        background: '#1C1C1C',
        iconColor: '#FF0000',
        confirmButtonColor: '#424242',
        confirmButtonText: "<span style='font-family: Inconsolata, monospace'>OK</span>",
      })
    }
  }
}
