<div class="menu-container">
  <div class="logo">
    <img src="assets/logosetinstone.webp" alt="Logo">
  </div>
  <nav class="menu-items" >
    <a href="{{ menu_URL }}/dashboard" target="_blank" class="menu-item">✨ Secure Your SmartContract</a>

    <a href="{{ menu_URL }}/dashboard" target="_blank" class="menu-item">📊 SignIn</a>
  </nav>

</div>
<div class="container">
  <h1 class="scan-title">SCAN SCORE</h1>
  <div class="scan-report" *ngIf="!errorMessage">
    <br><br>
    <h2>{{ animatedNumber }}%</h2>
    
    <div class="score-bar">
      <div class="score-fill"
        [style.width]="scanScore + '%'"
        [style.background]="scanScore <= 60 ? 
        'linear-gradient(to right, #f17474bd, #282827)' :
        scanScore <= 80 ?
        'linear-gradient(to right, #f6ff00e1, #282827)' :
        scanScore == 100 ?
        '#00FF00' :
        'linear-gradient(to right, #00b050, #282827)'"
      ></div>
    </div>

    <div>
      <h3 class="message" *ngIf="scanData.message2">{{ scanData.message2 ? scanData.message2 : " " }}</h3>
      <h3 class="message" *ngIf="scanData.message" style="margin-top: 2.25rem;"><strong>Expert opinion: </strong>{{ scanData.message ? scanData.message : " " }}</h3>
    </div>

    <div class="info">
      <h3><strong>Generated on:</strong> <br><br>
        <p>{{ scanData.resultCreationTime }}<br></p>
      </h3>
      <h3><strong>Valid until: </strong> <br><br>
        <p>{{ scanData.resultExpirationTime }}<br></p>
      </h3>
      <h3 style="overflow-x: auto;">
        <strong>Scanned Content: </strong><br><br>
        
        <!-- If it's a GitHub link -->
        <a *ngIf="scanData.contentScanned?.includes('github.com')" 
           [href]="scanData.contentScanned" 
           class="content-scanned" 
           target="_blank">
          {{ scanData.contentScanned }}
        </a>
      
        <!-- If no GitHub link, display bc_address and bc_name, clicking navigates to contentScanned -->
        <a *ngIf="!scanData.contentScanned?.includes('github.com') && scanData.bc_address && scanData.bc_name" 
           [href]="scanData.contentScanned" 
           class="content-scanned" 
           target="_blank">
          {{ scanData.bc_address }} | {{ scanData.bc_name }}
        </a>
      
        <!-- If no content or address, just display raw contentScanned -->
        <span *ngIf="!scanData.contentScanned?.includes('github.com') && (!scanData.bc_address || !scanData.bc_name)">
          {{ scanData.contentScanned }}
        </span>
      
        <br><br>
      </h3>
      
      <h3 *ngIf="scanData.vulReportUrl"><strong>Vulnerabilities Report: </strong><br><br>
        <a  [href]="scanData.vulReportUrl" class="content-scanned" target="_blank">{{ scanData.vulReportUrl }}</a>
        <br><br>
      </h3>
    </div>

    <h3 class="vul-summary-title" >Vulnerabilities Summary:</h3>
    <div class="vulnerability-summary">
      <p>🟥 High: {{ scanData.scan.VULS.flaws.High }}</p>
      <p>🟧 Medium: {{ scanData.scan.VULS.flaws.Medium }}</p>
      <p>🟨 Low: {{ scanData.scan.VULS.flaws.Low }}</p>
      <p>🟪 Warning: {{ scanData.scan.VULS.warnings }}</p>
    </div>

     <!-- Trust Indicators Section -->
     <div class="trust-indicators" *ngIf="scanData.scan.Other.VC_etherScan !== null">
      <h3>Trust Indicators:</h3>
      <p class="trust-indicators-p green" *ngIf="scanData.scan.Other.VC_etherScan === true">
        <span>✅</span> is on the list of smart contracts verified by Etherscan
      </p>
      <p class="trust-indicators-p orange" *ngIf="scanData.scan.Other.VC_etherScan === false">
        <span>⚠️</span> is not on the list of smart contracts verified by Etherscan
      </p>
    </div>

    <div class="report-button">
      <br><br>
      
       <a [href]="REQUEST_URL" target="_blank" rel="noopener noreferrer">
	       <button class="get-report-button">
     
          <img src="assets/logosetinstone.webp" alt="Logo">
     
           Your Vulnerability Report In Minutes
        </button>
      </a>
      <br><br>
    </div>

    <!--
    <div class="note"><br><br>
      <p>Note that the displayed scan only exposes common vulnerabilities found in the assessed codebase.</p>
      <p>To dive deeper into the scan results or obtain a detailed correction of the codebase, feel free to contact us.</p>
      <br><br>
         <a [href]="REQUEST_URL" target="_blank" rel="noopener noreferrer">
        <button class="get-report-button">
          Your Vulnerability Report In Minutes
        </button>
      </a>
    </div>
    -->
    <div class="share-scan">
      <div class="logo">
        <img src="assets/logosetinstone.webp" alt="Logo">
      </div>
      <h3>Share Your Scan</h3>
      <div class="links">
        <a (click)="copyToClipboard()">
          <img class="copy-link-icon" 
               [src]="isLinkCopied ? '../../../assets/checkedIcon.png' : '../../../assets/CopyLinkWhiteIcon.png'" 
               alt="Copy Link Icon">
        </a>
        <a [href]="shareOnTwitter" target="_blank">
          <img class="X-icon" src="../../../assets/XWhiteIcon.jpg" alt="Share on Twitter">
        </a>
        <a [href]="shareOnTelegram" target="_blank">
          <img class="telegram-icon" src="../../../assets/TelegramWhiteIcon.jpg" alt="Share on Telegram">
        </a>
      </div>
    </div>
  </div>

  <div class="scan-report" *ngIf="errorMessage === 'loading'">
    <br><br>
    <h4>The analysis is in progress and will take several minutes to complete.</h4>
    <div class="report-button">
      <br><br>
      <button class="get-report-button" (click)="fetchScanData()">   
        <svg 
         class="refresh-svg {{ isLoading ? 'animate' : '' }}" 
         xmlns="http://www.w3.org/2000/svg" width="24" height="21.986">
          <path d="M19.841 3.24A10.988 10.988 0 0 0 8.54.573l1.266 3.8a7.033 7.033 0 0 1 8.809 9.158L17 11.891v7.092h7l-2.407-2.439A11.049 11.049 0 0 0 19.841 3.24zM1 10.942a11.05 11.05 0 0 0 11.013 11.044 11.114 11.114 0 0 0 3.521-.575l-1.266-3.8a7.035 7.035 0 0 1-8.788-9.22L7 9.891V6.034c.021-.02.038-.044.06-.065L7 5.909V2.982H0l2.482 2.449A10.951 10.951 0 0 0 1 10.942z"/>
        </svg>
        &nbsp;
        Refresh
      </button>
      <br><br>
    </div>


    <div class="note">
      <p>Note that the displayed scan only exposes common vulnerabilities found in the assessed codebase.</p>
      <p>To dive deeper into the scan results or obtain a detailed correction of the codebase, feel free to contact us.</p>
      <br><br>
      <a [href]="REQUEST_URL" target="_blank" rel="noopener noreferrer"><button class="get-report-button">Get Your Vulnerability Report</button></a>
    </div>
  
    <div class="share-scan">
      <div class="logo">
        <img src="assets/logosetinstone.webp" alt="Logo">
      </div>
    </div>
  </div>

  <div class="scan-report" *ngIf="errorMessage !== 'loading' && errorMessage !== null">
    <br><br><span style="font-size:xx-large;">❌</span><br>
    <h4>{{errorMessage}}</h4>

    <div class="info">
      <p><strong>Generated on: </strong>{{ scanData.resultCreationTime }}</p><br>
      <p><strong>Valid until: </strong>{{ scanData.resultExpirationTime }}</p><br>
      <p>Scanned Content: <br><br>
      <a *ngIf="scanData.contentScanned.includes('github.com')" [href]="scanData.contentScanned" class="content-scanned" target="_blank">{{ scanData.contentScanned }}</a>
      <span *ngIf="!scanData.contentScanned.includes('github.com')">{{ scanData.contentScanned }}</span></p>
    </div>
    
   
    

    <div class="note">
      <br><br>
      <a [href]="REQUEST_URL" target="_blank" rel="noopener noreferrer"> <button class="get-report-button">Get Your Vulnerability Report</button></a>
    </div>
  
    
    <div class="logo">
      <img src="assets/logosetinstone.webp" alt="Logo">
    </div>
    
  </div>
  <br>
<div>
