<div class="container mt-4">
  <table class="table table-bordered text-center">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Content</th>
        <th scope="col">Status</th>
        <th scope="col">Scan Score</th>
        <th scope="col">Scan</th>
        <th scope="col">Creation</th>
        <th scope="col">Expiration</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let report of auditReports">
        <td>
          <ng-container *ngIf="isGithubRepo(report.content)">
            <a [href]="report.content" target="_blank" class="text-success">{{ getRepoName(report.content) }}</a>
          </ng-container>
          <ng-container *ngIf="isDeployedSC(report.content)">
            <a [href]="report.addressLink" target="_blank" class="text-success">{{ report.content }}</a>
          </ng-container>
          <ng-container *ngIf="!isGithubRepo(report.content) && !isDeployedSC(report.content)">
            {{ report.content }}
          </ng-container>
        </td>
        <td>{{ report.status }}</td>
        <td>{{ formatGrade(report.grade) }} %</td>
        <td class="scan-td">
          <a [href]="report.scanURL" target="_blank" class="text-success" [style.margin-left]="report.withReport ? '' : '-1.5em'">scan</a>
          <p *ngIf="report.withReport">📄</p>
        </td>
        <td>{{ report.resultCreationTime | customDate:report.status }}</td>
        <td>{{ report.resultExpirationTime | customDate:report.status }}</td>
        <td>
          <button (click)="deleteReport(report.hash)" class="delete-button">Delete</button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Loading indicator -->
  <div *ngIf="loading" class="text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <!-- Pagination -->
  <nav aria-label="Audit reports pagination" *ngIf="!loading && totalPages > 1" class="pagination-container">
    <ul class="pagination">
      <li class="page-item" [class.hidden]="currentPage === 1">
        <a class="page-link prev" href="#" (click)="previousPage(); $event.preventDefault()" aria-label="Previous page">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index"
          [class.active]="currentPage === i + 1">
        <a class="page-link" href="#" (click)="goToPage(i + 1); $event.preventDefault()">{{ i + 1 }}</a>
      </li>
      <li class="page-item" [class.hidden]="currentPage === totalPages">
        <a class="page-link next" href="#" (click)="nextPage(); $event.preventDefault()" aria-label="Next page">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</div>