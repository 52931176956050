<body>
  <div class="top-container">
    <img class="SetInStone-logo" src="../../../assets/logosetinstone.webp" alt="Set In Stone Logo" />
    <div class="nav-links">
      <h1 class="home-title">
        <a href="{{ linkLab }}">Home</a> |
        <a href="{{ linkHome }}">Pre-Audit</a>
      </h1>
    </div>
  </div>
  <article id="content" class="page sans">
    <header *ngIf="isLoaded">
      <img class="page-cover-image" [src]="coverImageUrl" style="object-position: center 65.41%" />
      <div class="page-header-icon page-header-icon-with-cover"><span class="icon">🔒</span></div>
      <h2 class="page-title">
        {{ headerTitle }} <span style="font-size: smaller;">GMT</span>
      </h2>
      <p class="page-description">
        {{ pageDescription }}
      </p>
    </header>
    <div class="page-body" *ngIf="isLoaded">
      <h1>I. Introduction</h1>
      <p><span [innerHTML]="introText"></span></p>

      <div class="stats-container" *ngIf="isLoaded" style="margin-top: 0.5rem;">
        <div class="stats-title">Vulnerability List</div>
        <div class="severity-list">
          <div class="severity-item">
            <mat-icon>error</mat-icon>
            <span>Vulnerabilities: </span>
            <span class="severity-count">{{ count.VULNERABILITIES }}</span>
          </div>
          <div class="severity-item">
            <mat-icon>warnings</mat-icon>
            <span>Vulnerable Patterns: </span>
            <span class="severity-count">{{ count.VULPATTERNS }}</span>
          </div>
          <div class="severity-item">
            <mat-icon>info</mat-icon>
            <span>Warnings: </span>
            <span class="severity-count">{{ count.WARNINGS }}</span>
          </div>
        </div>
      </div>

      <!-- Version Desktop -->
      <div class="cost-container" *ngIf="isLoaded && !isMobile">
        <div class="cost-title">Full Analysis Cost Estimation <span style="font-weight: lighter; font-size: smaller;">(Including Taxes)</span></div>
        
        <div class="cost-item">
          <span class="cost-description">Generate a Full Analysis report.</span>
          <span class="cost-price">€{{ cost }}</span>
        </div>
        <div class="cost-item">
          <span class="cost-description">One of our experts fixes identified vulnerabilities.</span>
          <a href="{{contactLink}}" class="cost-price" style="text-decoration: none;">Contact Us</a>
        </div>
        <div class="cost-item">
          <span class="cost-description">Beyond fundamental security, contact us and conduct an advanced audit.</span>
          <a href="{{contactLink}}" class="cost-price" style="text-decoration: none;">Contact Us</a>
        </div>
      </div>

      <!-- Version Mobile -->
      <div class="cost-container" *ngIf="isLoaded && isMobile">
        <div class="cost-title">Full Analysis Cost Estimation <span style="font-weight: lighter; font-size: smaller;">(Including Taxes)</span></div>
        <div class="cost-item">
          <span class="cost-description">Generate a Full Analysis report</span>
          <span class="cost-price">€{{ cost }}</span>
        </div>
        <div class="cost-item" style="margin-top: 0.25rem">
          <span class="cost-description">One of our experts fixes identified vulnerabilities</span>
          <a href="{{contactLink}}" class="cost-price" style="text-decoration: none;">Contact Us</a>
        </div>
        <div class="cost-item">
          <span class="cost-description">Beyond fundamental security, contact us and conduct an advanced audit.</span>
          <a href="{{contactLink}}" class="cost-price" style="text-decoration: none;">Contact Us</a>
        </div>
      </div>

      <h1>II. Vulnerabilities</h1>
      <h4 style="color: white" *ngIf="count.VULNERABILITIES > 0">
        This part brings together the most important vulnerabilities that alter the fundamental security of your smart
        contracts.
      </h4>
      <div class="vulnerability-section" *ngIf="nbHigh === 0">
        <p>
          Great news! We found no <strong> isolated vulnerabilities with critical impact</strong> in your analysis.
        </p>
      </div>
      <div class="vulnerability-report">
        <ng-container *ngFor="let category of sortedVulnerabilities">
          <div
            [id]="vulnerabilities"
            id="vulnerabilities"
            class="vulnerability-section"
            *ngIf="sortedVulnerabilities.length > 0"
          >
            <ng-container *ngFor="let vulnerability of category.vulnerabilities">
              <h3 (click)="toggleVulnerabilityDetail(vulnerability)">
                <span class="icon-wrapper"
                  ><span class="toggle-icon" [ngClass]="{ collapsed: !vulnerability.isExpanded }">▼</span></span
                >
                <span
                  class="clickable-text-mobile"
                  (mouseenter)="toggleIconBackground(true)"
                  (mouseleave)="toggleIconBackground(false)"
                  title="Click to expand/collapse"
                  >2.{{ vulnerability.id }})
                </span>
                <span
                  *ngIf="!isMobile"
                  class="clickable-text-mobile"
                  (mouseenter)="toggleIconBackground(true)"
                  (mouseleave)="toggleIconBackground(false)"
                  title="Click to expand/collapse"
                  >{{ vulnerability.check }} - ({{ vulnerability.impact }})</span
                >
                <span
                  *ngIf="isMobile"
                  class="clickable-text-mobile"
                  (mouseenter)="toggleIconBackground(true)"
                  (mouseleave)="toggleIconBackground(false)"
                  title="Click to expand/collapse"
                  >{{ vulnerability.check }}</span
                >
              </h3>

              <div *ngIf="vulnerability.isExpanded">
                <p class="indent"><strong>Impact: </strong> <span [innerHTML]="vulnerability.impact"></span></p>
                <p class="indent"><strong>Confidence: </strong> <span [innerHTML]="vulnerability.confidence"></span></p>
                <p class="indent" *ngIf="!isMobile">
                  <ng-container *ngIf="!isUpload && getGitHubURL(vulnerability.position) !== ''; else notClickable">
                    <strong>Position: </strong>
                    <a [href]="getGitHubURL(vulnerability.position)" target="_blank" class="position-button">
                      <span [innerHTML]="vulnerability.position"></span>
                    </a>
                  </ng-container>
                  <ng-template #notClickable>
                    <strong>Position: </strong>
                    <span [innerHTML]="vulnerability.position"></span>
                  </ng-template>
                </p>
                <p class="indent" *ngIf="isMobile">
                  <ng-container *ngIf="!isUpload && getGitHubURL(vulnerability.position) !== ''; else notClickable">
                    <strong>Position: </strong>
                    <a [href]="getGitHubURL(vulnerability.position)" target="_blank" class="position-button">
                      <span *ngIf="vulnerability.position !== ''">{{ getLastSegment(vulnerability.position) }}</span>
                    </a>
                  </ng-container>

                  <ng-template #notClickable>
                    <strong>Position: </strong>
                    <span *ngIf="vulnerability.position !== ''">{{ getLastSegment(vulnerability.position) }}</span>
                  </ng-template>
                </p>
                
                <p class="indent"><strong>Analysis: </strong></p>
                <p class="indent" style="margin-bottom: 1.5rem;"><span [innerHTML]="vulnerability.vul_analysis"></span></p>

                <p class="indent" *ngIf="vulnerability.vul_solution !== ''">
                  <strong>Guidance and correction advice :</strong><br />
                  <span [innerHTML]="vulnerability.vul_solution"></span>
                </p>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div *ngIf="free && nbVuln > 0" class="vulnerability-section" style="margin-top: 2.5rem">
          <p>
            🚨 We have identified<strong> {{ nbVuln }} other(s) Vulnerability(ies).</strong> To correct them, carry out
            a <a href="{{ URL }}" target="_blank">Full Analysis of your code</a>.
          </p>
        </div>
        <div class="vulnerability-section">
          <h2>III. Vulnerable Patterns</h2>
          <h4 style="color: white" *ngIf="!free && count.VULPATTERNS > 0">
            We detected patterns that were frequently repeated in your codebase. If they are not voluntary, please take
            note of them to strengthen the fundamental security of your code.
          </h4>
          <ng-container *ngFor="let category of sortedVulnerabilitiesPattern; let categoryIndex = index">
            <div
              [id]="vulnerabilitiesPatterns"
              id="vulnerabilitiesPatterns"
              class="vulnerability-section"
              *ngIf="sortedVulnerabilitiesPattern.length > 0"
            >
              <ng-container *ngFor="let vulnerability of category.vulnerabilities; let vulnerabilityIndex = index">
                <h3 (click)="toggleVulnerabilityDetailPattern(vulnerability)">
                  <span class="icon-wrapper"
                    ><span class="toggle-icon" [ngClass]="{ collapsed: !vulnerability.isExpanded }">▼</span></span
                  >
                  <span
                    class="clickable-text-mobile"
                    (mouseenter)="toggleIconBackground(true)"
                    (mouseleave)="toggleIconBackground(false)"
                    title="Click to expand/collapse"
                    >3.{{ vulnerability.id }})
                  </span>
                  <span
                    *ngIf="!isMobile"
                    class="clickable-text-mobile"
                    (mouseenter)="toggleIconBackground(true)"
                    (mouseleave)="toggleIconBackground(false)"
                    title="Click to expand/collapse"
                    >{{ vulnerability.check }} - ({{ vulnerability.impact }})</span
                  >
                  <span
                    *ngIf="isMobile"
                    class="clickable-text-mobile"
                    (mouseenter)="toggleIconBackground(true)"
                    (mouseleave)="toggleIconBackground(false)"
                    title="Click to expand/collapse"
                    >{{ vulnerability.check }}</span
                  >
                </h3>

                <div *ngIf="vulnerability.isExpanded">
                  <p class="indent"><strong>Impact: </strong> <span [innerHTML]="vulnerability.impact"></span></p>
                  <p class="indent">
                    <strong>Confidence: </strong> <span [innerHTML]="vulnerability.confidence"></span>
                  </p>
                  <div class="position-list-container">
                    <p class=""><strong>Occurrence: </strong> <span [innerHTML]="vulnerability.count"></span></p>
                    <div class="position-list-heading">
                      <strong>Positions:</strong>
                    </div>
                    <div class="position-list">
                      <ul>
                        <li
                          *ngFor="
                            let position of vulnerability.position2.slice(
                              0,
                              showAll ? vulnerability.position2.length : 5
                            );
                            let i = index
                          "
                          class="position-item"
                        >
                        <ng-container *ngIf="!isUpload && getGitHubURL(position) !== ''; else notClickable">
                          <a *ngIf="position !== ''" [href]="getGitHubURL(position)" target="_blank">{{ position }}</a>
                        </ng-container>
                        <ng-template #notClickable>
                          <p style="margin: 0;" *ngIf="position !== ''">{{ position }}</p>
                        </ng-template>
                        </li>
                      </ul>
                      <div
                        *ngIf="!showAll && vulnerability.position2.length > 5"
                        class="show-more-button"
                        (click)="toggleShowAll()"
                      >
                        Show more
                      </div>
                      <div *ngIf="showAll" class="show-less-button" (click)="toggleShowAll()">Show less</div>
                    </div>
                  </div>

                  <p class="indent">
                    <strong>Analysis: </strong>
                  </p>
                  <p class="indent" style="margin-bottom: 1.5rem;">
                    <span [innerHTML]="vulnerability.vul_analysis"></span>
                  </p>
                  <p class="indent" *ngIf="vulnerability.vul_solution !== ''">
                    <strong>Guidance and correction advice, for instance, the first file:</strong><br />
                    <span [innerHTML]="vulnerability.vul_solution"></span>
                  </p>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="vulnerability-section" *ngIf="count.VULPATTERNS === 0 && !free">
            <p>
              Great news! We found no <strong>Vulnerability Pattern</strong> in your analysis. This indicates a robust and
              secure implementation in this aspect of your project.
            </p>
          </div>
          <div *ngIf="free" class="vulnerability-section">
            <p style="margin-top: 1rem;">
              🚨 We have identified<strong> {{ count.VULPATTERNS }} Vulnerable Pattern(s).</strong> To correct them,
              carry out a <a href="{{ URL }}" target="_blank">Full Analysis of your code</a>.
            </p>
          </div>
        </div>
        <div class="vulnerability-section" style="margin-bottom: 2rem">
          <h2>IV. Warnings</h2>
          <h4 style="color: white" *ngIf="!free && count.WARNINGS > 0">
            We alert you that you can improve your code with the following points.
          </h4>
          <ng-container *ngFor="let category of sortedWarnings">
            <div [id]="warnings" id="warnings" class="vulnerability-section" *ngIf="sortedWarnings.length > 0">
              <ng-container *ngFor="let vulnerability of category.vulnerabilities">
                <h3 (click)="toggleVulnerabilityDetail(vulnerability)">
                  <span class="icon-wrapper"
                    ><span class="toggle-icon" [ngClass]="{ collapsed: !vulnerability.isExpanded }">▼</span></span
                  >
                  <span
                    class="clickable-text-mobile"
                    (mouseenter)="toggleIconBackground(true)"
                    (mouseleave)="toggleIconBackground(false)"
                    title="Click to expand/collapse"
                    >4.{{ vulnerability.id }})
                  </span>
                  <span
                    *ngIf="!isMobile"
                    class="clickable-text-mobile"
                    (mouseenter)="toggleIconBackground(true)"
                    (mouseleave)="toggleIconBackground(false)"
                    title="Click to expand/collapse"
                    >{{ vulnerability.check }} - ({{ vulnerability.impact }})</span
                  >

                  <span
                    *ngIf="isMobile"
                    class="clickable-text-mobile"
                    (mouseenter)="toggleIconBackground(true)"
                    (mouseleave)="toggleIconBackground(false)"
                    title="Click to expand/collapse"
                    >{{ vulnerability.check }}</span
                  >
                </h3>

                <div *ngIf="vulnerability.isExpanded">
                  <p class="indent"><strong>Impact: </strong> <span [innerHTML]="vulnerability.impact"></span></p>
                  <p class="indent">
                    <strong>Confidence: </strong> <span [innerHTML]="vulnerability.confidence"></span>
                  </p>
                  
                  <p class="indent" *ngIf="!isMobile">
                    <ng-container *ngIf="!isUpload && getGitHubURL(vulnerability.position) !== ''; else notClickable">
                      <strong *ngIf="vulnerability.position !== ''">Position: </strong>
                      <a [href]="getGitHubURL(vulnerability.position)" target="_blank" class="position-button">
                        <span [innerHTML]="vulnerability.position"></span>
                      </a>
                    </ng-container>
                    <ng-template #notClickable>
                      <strong *ngIf="vulnerability.position !== ''">Position: </strong>
                      <span [innerHTML]="vulnerability.position"></span>
                    </ng-template>
                  </p>
                  <p class="indent" *ngIf="isMobile">
                    <ng-container *ngIf="!isUpload && getGitHubURL(vulnerability.position) !== ''; else notClickable">
                      <strong *ngIf="vulnerability.position !== ''">Position: </strong>
                      <a [href]="getGitHubURL(vulnerability.position)" target="_blank" class="position-button">
                        <span *ngIf="vulnerability.position !== ''">{{ getLastSegment(vulnerability.position) }}</span>
                      </a>
                    </ng-container>
  
                    <ng-template #notClickable>
                      <strong *ngIf="vulnerability.position !== ''">Position: </strong>
                      <span *ngIf="vulnerability.position !== ''">{{ getLastSegment(vulnerability.position) }}</span>
                    </ng-template>
                  </p>
                  <p class="indent">
                    <strong>Analysis: </strong>
                  </p>
                  <p class="indent" style="margin-bottom: 1.5rem;"><span [innerHTML]="vulnerability.vul_analysis"></span></p>

                  <p class="indent" *ngIf="vulnerability.vul_solution !== ''">
                    <strong>Guidance and correction advice :</strong><br />
                    <span [innerHTML]="vulnerability.vul_solution"></span>
                  </p>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="vulnerability-section" *ngIf="count.WARNINGS === 0 && !free">
            <p>
              Great news! We found no <strong>Warnings</strong> in your analysis. This indicates a robust and
              secure implementation in this aspect of your project.
            </p>
          </div>
          <div *ngIf="free" class="vulnerability-section">
            <p style="margin-top: 1rem;">
              🚨 We have identified<strong> {{ count.WARNINGS }} Warning(s).</strong> To correct them, carry out a
              <a href="{{ URL }}" target="_blank">Full Analysis of your code</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </article>
  <span class="sans" style="font-size: 14px; padding-top: 1em; padding-bottom: 3em"></span>
  <button *ngIf="isLoaded" id="scrollToTopButton" title="Go to top" (click)="scrollToTop()">
    <mat-icon class="icon-up">arrow_upward</mat-icon>
  </button>
</body>
