import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/app/environments/environment';
import { ServerService } from 'src/app/server.service';
import * as CryptoJS from 'crypto-js';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password',
  templateUrl: './dashboard-change-password.component.html',
  styleUrls: ['./dashboard-change-password.component.css', '../../../app.component.css']
})
export class DashboardChangePasswordComponent {
  changePasswordForm: FormGroup;
  @Input() userEmail: string = '';
  @Input()
  closeDialog!: () => void;

  constructor(
    private fb: FormBuilder,
    private serverService: ServerService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<DashboardChangePasswordComponent>,
  ) {
    this.changePasswordForm = this.fb.nonNullable.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        this.passwordStrengthValidator() 
      ]],
      confirmPassword: ['', Validators.required]
    }, { validators: this.passwordMatchValidator() });
  }

  passwordMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const password = form.get('password')?.value;
      const confirmPassword = form.get('confirmPassword')?.value;
      
      return password && confirmPassword && password !== confirmPassword ? { mismatch: true } : null;
    };
  }

  passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasDigit = /\d/.test(value);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const validLength = value.length >= 8;

      const passwordValid = hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar && validLength;
      
      return !passwordValid ? { weakPassword: true } : null;
    };
  }

  get password() {
    return this.changePasswordForm.get('password');
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  async onSubmit(): Promise<void> {
    if (this.changePasswordForm.valid) {
      const { password } = this.changePasswordForm.value;
      const encryptedEmail = CryptoJS.AES.encrypt(this.userEmail, environment.secretKey).toString();
      const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

      try {
        await this.serverService.updatePassword({ email: encryptedEmail, password: hashedPassword }).then((response: any) => {
          if (response.message === 'Password updated successfully') {
            this.snackBar.open('Password Successfully Updated, Please Sign In', 'Close', {
              duration: 7000,
              panelClass: ["snackBarSuccess"]
            });
            this.dialogRef.close();
          } else {
            this.snackBar.open('Password update failed', 'Close', {
              duration: 7000,
              panelClass: ["snackBarError"]
            });
          }
        });
        
      } catch (error) {
        // console.error('Password update failed: ', error);
        this.snackBar.open('Password update failed', 'Close', {
          duration: 7000,
          panelClass: ["snackBarError"]
        });
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
