import { Component, Input } from '@angular/core';
import { ServerService } from 'src/app/server.service';
import { trigger, transition, style } from '@angular/animations';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
  animations: [
    // Animation trigger for progress bar width transition
    trigger('progressWidth', [
      transition('* => *', [style({ width: '0%' })])
    ])
  ],
})
export class ProgressBarComponent {
  @Input() score: any; // Input score from parent component
  @Input() message: any; // Input message from parent component

  presetPercentages: number[] = [20, 40, 60, 80, 100]; // Preset values for percentage mapping
  maxScore: number = 5000; // Maximum score threshold
  percent: any; // Current progress percentage
  cssPercent: any; // CSS percentage for progress bar
  cssPx: any; // Pixel offset for progress bar styling
  progressWidthState: any; // Used for controlling progress bar animation state
  isMobile: boolean = false; // Boolean to check if the user is on a mobile device
  grade: any; // Grade or level based on score
  color: any; // Color of progress bar based on grade

  constructor(public server: ServerService) {}

  // React to changes in input properties
  ngOnChanges(): void {
    this.isMobile = this.server.isMobile; // Check if the device is mobile

    // Logic for non-mobile devices
    if (!this.isMobile) {
      if (this.score >= this.maxScore) {
        this.setProgress(1, this.presetPercentages[0], 85, 25, '#F04038');
      } else if (this.score >= 1000) {
        this.setProgress(2, this.presetPercentages[1], 15, 40, '#ff6700');
      } else if (this.score >= 300) {
        this.setProgress(3, this.presetPercentages[2], -85, 60, '#F4B032');
      } else if (this.score >= 10) {
        this.setProgress(4, this.presetPercentages[3], -225, 80, '#469E68');
      } else if (this.score >= 0) {
        this.setProgress(5, this.presetPercentages[4], -375, 100, '#2E8B57');
      }
    }
    // Logic for mobile devices
    else {
      if (this.score >= this.maxScore) {
        this.setProgress(1, this.presetPercentages[0], 35, 25, '#F04038');
      } else if (this.score >= 1000) {
        this.setProgress(2, this.presetPercentages[1], -10, 40, '#ff6700');
      } else if (this.score >= 300) {
        this.setProgress(3, this.presetPercentages[2], -95, 60, '#F4B032');
      } else if (this.score >= 10) {
        this.setProgress(4, this.presetPercentages[3], -155, 80, '#469E68');
      } else if (this.score >= 0) {
        this.setProgress(5, this.presetPercentages[4], -190, 100, '#2E8B57');
      }
    }
  }

  // Helper function to set progress-related properties based on score
  private setProgress(grade: number, percent: number, cssPx: number, cssPercent: number, color: string): void {
    this.grade = grade;
    this.percent = percent;
    this.cssPx = cssPx;
    this.cssPercent = cssPercent;
    this.color = color;
    this.progressWidthState = percent; // Update the progress width state for animation
  }
}
