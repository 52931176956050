<div class="sign-in-container">
  <div class="sign-in-box">
    <h2>Sign In</h2>
    <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" id="email" formControlName="email" placeholder="Email" required />
        <div *ngIf="signInForm.get('email')?.invalid && (signInForm.get('email')?.dirty || signInForm.get('email')?.touched)">
          <small class="error" *ngIf="signInForm.get('email')?.errors?.['required']">Email is required.</small>
          <small class="error" *ngIf="signInForm.get('email')?.errors?.['email']">Please enter a valid email address.</small>
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" id="password" formControlName="password" placeholder="Password" required />
        <div *ngIf="signInForm.get('password')?.invalid && signInForm.get('password')?.dirty">
          <small class="error" *ngIf="signInForm.get('password')?.errors?.['required']">Password is required.</small>
          <small class="error">
            <p>Password must have at least a minimum length of 8 characters</p>
            <!-- <ul>
              <li>1 uppercase letter</li>
              <li>1 lowercase letter</li>
              <li>1 digit</li>
              <li>1 special character</li>
              <li>A minimum length of 8 characters</li>
            </ul> -->

          </small>
        </div>
      </div>
      <div class="forgot-password">
        <a (click)="openForgotPasswordDialog()">Forgot Password ?</a>
      </div>
      <button type="submit" class="sign-in-button" [disabled]="signInForm.invalid">Sign In</button>
    </form>
  </div>
</div>