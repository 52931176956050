import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string | null | undefined, status?: string): string {
    if (status === 'IN PROGRESS') {
      return 'IN PROGRESS';
    }
    if (!value || !this.isValidDate(value)) {
      return 'EXPIRED';
    }
    return this.datePipe.transform(value, 'EEE, dd MMM yyyy HH:mm:ss') + ' GMT';
  }

  private isValidDate(dateString: string): boolean {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }
}