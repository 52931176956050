<div class="sign-up-container">
    <div class="sign-up-box">
      <h2>Sign Up</h2>
      <form *ngIf="!showVerificationForm" [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" formControlName="email" placeholder="Email" />
          <div *ngIf="signUpForm.get('email')!.invalid && signUpForm.get('email')!.touched" class="error">
            Please enter a valid email.
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" formControlName="password" placeholder="Password" />
          <div *ngIf="signUpForm.get('password')!.invalid && signUpForm.get('password')!.touched" class="error">
            <p>Password must have at least:</p>
            <ul>
              <li>1 uppercase letter</li>
              <li>1 lowercase letter</li>
              <li>1 digit</li>
              <li>1 special character</li>
              <li>A minimum length of 8 characters</li>
            </ul>

          </div>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password</label>
          <input type="password" id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password" />
          <div *ngIf="signUpForm.hasError('mismatch') && signUpForm.get('confirmPassword')!.touched" class="error">
            Passwords do not match.
          </div>
        </div>
        <button type="submit" class="sign-up-button" [disabled]="signUpForm.invalid">Sign Up</button>
      </form>

      <form *ngIf="showVerificationForm" [formGroup]="verificationForm" (ngSubmit)="onVerificationSubmit()">
        <div class="form-group">
          <label for="verificationCode">Verification Code</label>
          <input type="number" id="verificationCode" formControlName="verificationCode" placeholder="Enter 8-digit code" />
          <div *ngIf="verificationForm.get('verificationCode')!.invalid && verificationForm.get('verificationCode')!.touched" class="error">
            Please enter a valid 8-digit verification code.
          </div>
        </div>
        <button type="submit" class="sign-up-button" [disabled]="verificationForm.invalid">Verify</button>
      </form>
    </div>
  </div>
