import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { ServerService } from 'src/app/server.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css']
})



export class DashboardMenuComponent implements OnInit {
  isLoggedIn: boolean = false;
  menuTabSwitch: menutab = menutab.login;
  isMobile: boolean;

  constructor(
    private authService: AuthService,
    private serverService: ServerService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    return
  }

  signIn(): void {
    this.menuTabSwitch = menutab.login;
  }

  signUp(): void {
    this.menuTabSwitch = menutab.signUp;
  }

  signOut(): void {
    // console.log(this.authService.getToken());
    this.serverService.logoutDashboard();
    this.authService.logout();
    this.isLoggedIn = false;
  }
}
export enum menutab {
  login = "login",
  signUp = "signUp"
};
