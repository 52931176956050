import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenKey = 'authTokenDashboard';

  constructor() {}

  /**
   * Logs in the user by storing the authentication token in local storage.
   * @param token - The authentication token received from the server.
   */
  login(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  /**
   * Logs out the user by removing the authentication token from local storage.
   */
  logout(): void {
    localStorage.removeItem(this.tokenKey);
  }

  /**
   * Checks if the user is logged in by verifying the presence of the authentication token.
   * @returns boolean - Returns true if the user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    return !!localStorage.getItem(this.tokenKey);
  }

  /**
   * Retrieves the stored authentication token.
   * @returns string | null - Returns the token if present, null otherwise.
   */
  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

}
