import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/app/environments/environment';
import { ServerService } from 'src/app/server.service';
import * as CryptoJS from 'crypto-js';
import { AuthService } from '../../../auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard-signup',
  templateUrl: './dashboard-signup.component.html',
  styleUrls: ['./dashboard-signup.component.css', '../../../app.component.css']
})
export class DashboardSignupComponent {
  signUpForm: FormGroup;
  verificationForm: FormGroup;
  showVerificationForm: boolean = false;

  constructor(
    private fb: FormBuilder,
    private serverService: ServerService,
    private authService: AuthService,
    private snackBar: MatSnackBar  
  ) {
    this.signUpForm = this.fb.nonNullable.group({
      email: ['', [Validators.required, Validators.email,Validators.minLength(8), Validators.maxLength(50)]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        this.passwordStrengthValidator() 
      ]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(50)]]
    }, { validators: this.passwordMatchValidator });

    this.verificationForm = this.fb.group({
      verificationCode: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]]
    });
  }

  passwordMatchValidator: ValidatorFn = (form: AbstractControl): ValidationErrors | null => {
    const password = form.get('password')?.value;
    const confirmPassword = form.get('confirmPassword')?.value;

    return password && confirmPassword && password !== confirmPassword 
      ? { mismatch: true } 
      : null;
  }

  // Custom validator for password strength
  passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasDigit = /\d/.test(value);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const validLength = value.length >= 8;

      const passwordValid = hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar && validLength;
      
      return !passwordValid ? { weakPassword: true } : null;
    };
  }

  async onSubmit(): Promise<void> {
    if (this.signUpForm.valid) {
      const { email } = this.signUpForm.value;
      const encryptedEmail = CryptoJS.AES.encrypt(email, environment.secretKey).toString();
     

      this.serverService.requestSignupCode(encryptedEmail)
        .then(response => {
          
          if (response.message === 'Confirm email sent successfully') {
            this.snackBar.open('Verification code sent to your email. Please check and enter the code.', 'Close', {
              duration: 7000,
              panelClass: ["snackBarSuccess"]
            });
            this.showVerificationForm = true; 
          } else {
            this.snackBar.open('An unexpected error occurred. Please try again.', 'Close', {
              duration: 7000,
              panelClass: ["snackBarError"]
            });
          }              
        })
        .catch((error: any) => {
      
          this.snackBar.open('There was an error while sending the verification code. Please try again. ('+error.error.message+')', 'Close', {
            duration: 7000,
            panelClass: ["snackBarError"]
          });
        });
    }
  }

  async onVerificationSubmit(): Promise<void> {
    if (this.verificationForm.valid) {
      const { email, password } = this.signUpForm.value;
      const { verificationCode } = this.verificationForm.value;
      
      const obj = { token: verificationCode, email: email};
      const jsonStr = JSON.stringify(obj) // Convert the object to a JSON string
      const encryptedObject = CryptoJS.AES.encrypt(jsonStr, environment.secretKey).toString()
    
      const encryptedEmail = CryptoJS.AES.encrypt(email, environment.secretKey).toString();
      const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

      this.serverService.VerifyTokenDashboard(encryptedObject)
        .then( (response1: {message: string}) => {
          if (response1.message === 'Token is valid') {
            this.serverService.registerUser({ email: encryptedEmail, password: hashedPassword })
            .then((response) => {
              
              if (response.message === 'register successful') {
                this.authService.login(response.token);
                this.snackBar.open('Account Successfully Created and Verified!', 'Close', {
                  duration: 7000,
                  panelClass: ["snackBarSuccess"]
                });
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                this.snackBar.open('An unexpected error occurred. Please try again.', 'Close', {
                  duration: 7000,
                  panelClass: ["snackBarError"]
                });
              }
            })
            .catch(error => {
              if (error.status === 409) {
                this.snackBar.open('This email is already registered. Please use a different email address or Sign in with this one.', 'Close', {
                  duration: 7000,
                  panelClass: ["snackBarError"]
                });
              } else {
                // console.error('Registration failed', error);
                this.snackBar.open('Registration failed. Please try again.', 'Close', {
                  duration: 7000,
                  panelClass: ["snackBarError"]
                });
              }
            });
          } else {
            this.snackBar.open('Verification failed. Please try again.', 'Close', {
              duration: 7000,
              panelClass: ["snackBarError"]
            });
          }
        })
        .catch((error: any) => {
          // console.error('Verification failed', error);
          this.snackBar.open('Verification failed. Please try again.', 'Close', {
            duration: 7000,
            panelClass: ["snackBarError"]
          });
        });
    }
  }

  get passwordMismatch() {
    return this.signUpForm.getError('mismatch') !== null;
  }
}
