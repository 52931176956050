<div class="menu-container">
  <div class="logo">
    <img src="assets/logosetinstone.webp" alt="Logo">
  </div>
  <nav class="menu-items" >
    <a routerLink="/" class="menu-item">
      ✨ Secure Your SmartContract
    </a>
    <a *ngIf="!isLoggedIn && menuTabSwitch != 'login' && !isMobile" (click)="signIn()" class="menu-item">SignIn</a>
    <a *ngIf="!isLoggedIn && menuTabSwitch != 'signUp' && !isMobile" (click)="signUp()" class="menu-item">SignUp</a>
    <a *ngIf="isLoggedIn && !isMobile" (click)="signOut()" class="menu-item">Sign Out</a>
  </nav>
</div>

<div class="container" *ngIf="!isMobile; else mobileMessage">  
  <app-dashboard-login *ngIf="!isLoggedIn && menuTabSwitch == 'login'"></app-dashboard-login>
  <app-dashboard-signup *ngIf="!isLoggedIn && menuTabSwitch == 'signUp'"></app-dashboard-signup>
  <app-dashboard-content *ngIf="isLoggedIn"></app-dashboard-content>
</div>

<ng-template #mobileMessage>
  <p class="mobile-message">Sorry, this feature is only available on the desktop version.</p>
  <a routerLink="/" class="back-button">Back To Homepage</a>
</ng-template>