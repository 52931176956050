import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { ServerService } from 'src/app/server.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css']
})



export class DashboardMenuComponent implements OnInit {
  isLoggedIn: boolean = false;
  menuTabSwitch: menutab = menutab.login;
  isMobile: boolean;
  firstLogin: boolean=false;

  creationDate: string='';
  nextUpdate: string='';
  endDate: string='';
  remainingUses: number=0;
  status:  'Individual' | 'Professional' | 'Custom' | 'Canceled' = 'Individual';
  userEmail: string = '';

  constructor(
    private authService: AuthService,
    private serverService: ServerService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = this.authService.isLoggedIn();
    if (this.isLoggedIn) await this.getUserData();
    const currentUrl = window.location.href;
    
    if (currentUrl.endsWith('#prices')) {
      this.menuTabSwitch = menutab.seePrices;
    } else if (currentUrl.endsWith('#signup')) {
      this.menuTabSwitch = menutab.signUp;
    } else if (currentUrl.endsWith('#api-keys')) {
      this.menuTabSwitch = menutab.apiKeys;
    }
    // else default is login page --> menuTabSwitch: menutab = menutab.login;
  }

  signIn(): void {
    this.menuTabSwitch = menutab.login;
  }

  signUp(): void {
    this.menuTabSwitch = menutab.signUp;
  }

  signOut(): void {
    // console.log(this.authService.getToken());
    this.serverService.logoutDashboard();
    this.authService.logout();
    this.isLoggedIn = false;
    this.menuTabSwitch = menutab.login;
  }

  seePrices(): void {
    this.menuTabSwitch = menutab.seePrices;
    window.location.href = window.location.href.replace(/#.*$/, '') + '#prices';
  }

  seeApiKeys(): void {
    this.menuTabSwitch = menutab.apiKeys;
    window.location.href = window.location.href.replace(/#.*$/, '') + '#api-keys';
  }

  seeContent(): void {
    this.menuTabSwitch = menutab.content;
    window.location.href = window.location.href.replace(/#.*$/, '')
  }

  setFirstLogin(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.firstLogin = true;
    this.seePrices();
  }

  async getUserData(): Promise<void> {
    const data = await this.serverService.getSubscriptionUserData();
    this.creationDate = new Date(data.creationDate).toLocaleDateString();
    this.endDate = new Date(data.endDate).toLocaleDateString();
    this.nextUpdate = new Date(data.nextUpdate).toLocaleDateString();
    this.remainingUses = data.remainingUses;
    this.userEmail = data.decryptedEmail;
    switch (data.status) {
      case 1:
        this.status = 'Individual';
        break;
      case 2:
        this.status = 'Professional';
        break;
      case 0:
        this.status = 'Canceled';
        break;
      case 3:
        this.status = 'Custom';
        break;
    }
  }

}


export enum menutab {
  login = "login",
  signUp = "signUp",
  content = "content",
  seePrices = "prices",
  apiKeys = "api-keys"
};
