import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServerService } from 'src/app/server.service'; // Assuming you have an ApiService for backend calls

interface ApiKey {
  label: string;
  key: string;
  createdAt: Date;
  remainingUses: number;
  historic: { timestamp: Date; type: string }[],
  showHistoric: boolean
}

@Component({
  selector: 'app-api-creation',
  templateUrl: './api-creation.component.html',
  styleUrls: ['./api-creation.component.css']
})
export class ApiCreationComponent implements OnInit {
  apiForm: FormGroup;
  apiKeys: ApiKey[] = [];
  generatedKey: string | null = null;
  warningMessage: string | null = null;
  loading: boolean = false; // Add loading state

  constructor(private fb: FormBuilder, private serverService: ServerService) {
    this.apiForm = this.fb.group({
      label: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.loadApiKeys(); // Load existing API keys on initialization
  }

  onSubmit() {
    if (this.apiForm.valid) {
      this.serverService.createApiKey(this.apiForm.value.label)
        .then(response => {
          this.generatedKey = response.key; // Assuming the response contains the generated key
          this.warningMessage = 'Note this API key, as it will not be shown again.';
          this.loadApiKeys(); // Refresh the list of API keys
        })
        .catch(error => {
          console.error('Error creating API key:', error);
          // Handle error (e.g., show a message to the user)
        });
    }
  }

  copyToClipboard() {
    if (this.generatedKey) {
      navigator.clipboard.writeText(this.generatedKey).then(() => {
        this.warningMessage = 'API key copied to clipboard!';
        setTimeout(() => {
          this.warningMessage = 'Note this API key, as it will not be shown again.';
        }, 5000);
      }).catch(err => {
        console.error('Failed to copy: ', err);
        this.warningMessage = 'Failed to copy API key.';
        setTimeout(() => {
          this.warningMessage = 'Note this API key, as it will not be shown again.';
        }, 5000);
      });
    }
  }

  async loadApiKeys(): Promise<void> {
    this.loading = true; // Set loading to true before fetching
    try {
      this.apiKeys = await this.serverService.getApiKeys(); // Fetch API keys
      this.apiKeys.forEach(apiKey => {
        apiKey.showHistoric = false; // Initialize the showHistoric property
      });
    } catch (error) {
      console.error('Error loading API keys:', error);
    } finally {
      this.loading = false; // Set loading to false after fetching
    }
  }

  deleteApiKey(key: string) {
    this.serverService.deleteApiKey(key)
      .then(() => {
        this.loadApiKeys(); // Refresh the list after deletion
      })
      .catch(error => {
        console.error('Error deleting API key:', error);
        // Handle error (e.g., show a message to the user)
      });
  }
}
